.ant-select-item-option-selected,
.ant-select-item-option-selected:hover {
    background-color: #40a9ff!important;
    color: #fff;
}

.ant-select-item-option:hover,
.ant-select-item-option-active {
    background-color: #40a9ff!important;
}

.ant-select-selector {
    border: 1px solid #5F6B78!important;
    background-color: #21282E!important;
}

.ant-select-arrow {
    color: #5F6B78;
}

.ant-select-disabled .ant-select-selection {
    border: 1px solid #5F6B78!important;
    background: #21282E;
    cursor: not-allowed;
    opacity: .9;
}

.ant-select-disabled {
    color: #fff;
}

.ant-select-selection--multiple .ant-select-selection__choice {
    color: #fff;
    background-color: #a6bace;
    border: 1px solid #5F6B78;
}

.ant-select-selection--multiple .ant-select-selection__choice__remove {
    color: #5F6B78;
}
