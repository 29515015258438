.page-header-tabs {
    margin-bottom: 0 !important;
}

.tabs .ant-tabs-top-bar {
    margin-bottom: 10px !important;
}


.page-header-tabs .ant-tabs-nav .ant-tabs-tab,
.tabs .ant-tabs-nav .ant-tabs-tab {
    padding: 12px 0;
}

.page-header-tabs .ant-tabs-nav .ant-tabs-tab span,
.tabs .ant-tabs-nav .ant-tabs-tab span {
    display: inline-block;
    text-align: center;
    min-width: 36px;
    margin-left: 3px;
    padding: 4px 6px;
    font-size: 12px;
    line-height: 1;
    color: #9ab2c1;
    border: 1px solid #596570;
    border-radius: 2em;
}


.page-header-tabs .ant-tabs-nav .ant-tabs-tab-active span,
.tabs .ant-tabs-nav .ant-tabs-tab-active span {
    color: #40a9ff;
    border-color: #40a9ff;
}


.ant-tabs-bottom .ant-tabs-bottom-bar {
    position: relative;
    top: -3px;
    margin-top: 0;
    margin-bottom: 0;
    border-top: 1px solid transparent;
    border-bottom: none;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
    height: 24px;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    height: 24px;
    margin: 0;
    margin-right: 2px;
    padding: 0 6px;
    line-height: 24px;
    color: #9aafc1;
    background: #343C43;
    border: 1px solid #596570;
    border-radius: 0 0 4px 4px;
    font-size: 10px;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    height: 24px;
    color: #fff;
    background: #21282E;
    border-color: #3F484E;
    border-top: 1px solid #21282E;
}

.ant-tabs-bottom-content {
    position: relative;
    z-index: 2;
}

.ant-tabs-bottom .ant-form-item {
    margin-bottom: 0;
}
