.ant-form-item {
    margin-bottom: 16px;
}

.ant-form-item2 {
    margin-bottom: 0;
}

.ant-form-item-label {
    line-height: 30px;
}

.ant-form-item-label label {
    font-size: 11px;
    line-height: 12px;
    color: #5F6B78;
    text-transform: uppercase;
}

.ant-form-item-required:before {
    position: relative;
    top: 1px;
    font-size: 11px;
}

.ant-form-explain, .ant-form-extra {
    margin-top: 0;
    font-size: 11px;
}

.form-block {
    margin-bottom: 30px;
    padding: 24px;
    border-radius: 12px;
    background-color: #2C343A;
}

.form-error {
    margin-bottom: 15px;
    padding: 8px 10px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    /*background: linear-gradient(30deg, rgb(240, 103, 168) 0%, rgb(247, 149, 80) 100%);*/
    background: linear-gradient(30deg, #FA3C71 0%, #F7642F 100%);
}
