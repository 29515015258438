.status {
    display: inline-block;
    min-width: 60px;
    min-height: 22px;
    text-align: center;
    padding: 0 8px;
    font-size: 12px;
    font-weight: 300;
    line-height: 22px;
    border: 1px solid #ccc;
    border-radius: 2em;
    white-space: nowrap;

    color: #000;
    fill: #000;
    background: #fff;
    user-select: none;
}

.status.size--small, .status.size--xs {
    min-height: 16px;
    padding: 1px 4px 2px;
    font-size: 10px;
    line-height: 16px;
}

/* NEW */
.status.s0 {
    color: #375d7b;
    fill: #375d7b;
    border-color: #65CAFC;
    background: #c2e2fc;
}

/* RESTORING */
.status.s11, .status.s12 {
    color: #625010;
    fill: #625010;
    border-color: #e1b426;
    background: #ffe37b;
}

/* RESTORED or PUBLISHING */
.status.s19, .status.s21, .status.s22 {
    color: #855896;
    fill: #855896;
    border-color: #D68FE7;
    background: #f0daff;
}


/* PUBLISHED */
.status.s29 {
    color: #488d47;
    fill: #488d47;
    border-color: #83E163;
    background: #dbffd9;
}

/* ERROR */
.status.s91, .status.s92 {
    color: #9e3a3a;
    fill: #9e3a3a;
    border-color: #FF625C;
    background: #ffd6d5;
}
