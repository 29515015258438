.container {
}

.item {
}

.itemWrap {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #48515b;
    background: #1D2329;
}

.itemLabel {
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 1;
    color: #7c899b;
}

.itemContent {

}

.itemContentEmpty:after {
    content: '-';
}