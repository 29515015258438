.ant-btn {
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    color: #7D91A5;
    background: #3C454D;
    border-color: #3C454D;
}

.ant-btn:hover, .ant-btn:focus {
    color: #7D91A5;
    background: #4b575f;
    border-color: #4b575f;
}

.ant-btn-disabled,
.ant-btn.disabled,
.ant-btn[disabled],
.ant-btn-disabled:hover,
.ant-btn.disabled:hover,
.ant-btn[disabled]:hover, .ant-btn-disabled:focus, .ant-btn.disabled:focus, .ant-btn[disabled]:focus, .ant-btn-disabled:active, .ant-btn.disabled:active, .ant-btn[disabled]:active, .ant-btn-disabled.active, .ant-btn.disabled.active, .ant-btn[disabled].active {
    color: #5f6f82;
    background: #3C454D;
    border-color: #3C454D;
    text-shadow: none;
    box-shadow: none;
}

.ant-btn-primary {
    color: #fff;
    background-color: #40a9ff;
    border-color: #40a9ff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
    color: #fff;
    background-color: #69c0ff;
    border-color: #69c0ff;
}

.ant-btn-danger {
    color: #fff;
    background-color: #D12F2F;
    border-color: #D12F2F;
}

.ant-btn-danger:hover,
.ant-btn-danger:focus {
    color: #fff;
    background-color: #dd3030;
    border-color: #dd3030;
}
