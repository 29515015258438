.ant-input-group-addon {
    color: #5f6f82;
    text-align: center;
    background-color: #2f3841;
    border: 1px solid #3F484E;
}

.ant-input {
    font-size: 12px;
    color: #fff;
    background-color: #21282E;
    background-image: none;
    border: 1px solid #3F484E;
}

.ant-input-number {
    color: #fff;
    background-color: #21282E;
    border: 1px solid #3F484E;
}

.ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 36px;
}

.ant-input-affix-wrapper {
    background-color: #21282E;
    background-image: none;
    border: 1px solid #3F484E;
}
