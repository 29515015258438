/* page transitions */

.page-enter {
    z-index: 2;
    opacity: 0;
}

.page-enter-active {
    z-index: 2;
    opacity: 1;
    transition: all .25s ease-out;
}

.page-enter-done {
    z-index: 2;
}

.page-exit {
    opacity: .1;
}

.page-exit-active {
    opacity: 0;
    transition: all .5s ease-out;
}

/* page slide-in */

.page-wrap--slide-in .page-enter {
    z-index: 2;
    opacity: 1;
    transform: translate(100%, 0);
}

.page-wrap--slide-in .page-enter-active {
    z-index: 2;
    transform: translate(0, 0);
    transition: all .5s ease-out;
}

.page-wrap--slide-in .page-enter-done {
    z-index: 2;
}

.page-wrap--slide-in .page-exit {
    opacity: 1;
    transform: translate(0, 0);
}

.page-wrap--slide-in .page-exit-active {
    opacity: 0;
    transform: translate(-50%, 0);
    transition: all .8s ease-out;
}


/* page slide-out */

.page-wrap--slide-out .page-enter {
    opacity: 1;
    z-index: 2;
    transform: translate(-100%, 0);
}

.page-wrap--slide-out .page-enter-active {
    opacity: 1;
    z-index: 2;
    transform: translate(0, 0);
    transition: all .5s ease-out;
}

.page-wrap--slide-out .page-enter-done {
    opacity: 1;
    z-index: 2;
}

.page-wrap--slide-out .page-exit {
    opacity: 1;
    transform: translate(0, 0);
}

.page-wrap--slide-out .page-exit-active {
    opacity: 0;
    transform: translate(100%, 0);
    transition: all .8s ease-out;
}
