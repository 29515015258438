.ant-checkbox-group-item {
    display: block;
}

.ant-checkbox-inner {
    border: 1px solid #5F6B78;
    background-color: #21282E
}

.ant-radio-button-wrapper {
    border-color: #5F6B78;
}
.ant-radio-button-wrapper:not(:first-child)::before {
    background:  #5F6B78;
}
.ant-radio-button-wrapper:first-child {
    border-left-color: #5F6B78;
}
.ant-radio-button-wrapper:last-child {
    border-right-color: #5F6B78;
}
